<template>
  <div>
    <h4 class="fw-bold mb-4 d-flex page-title">
      <span class="mr-auto">
        {{ $trans('Request service') }}
      </span>
      <button @click="addForm = true" class="btn btn-primary btn-sm mr-3">
        <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
          <line data-v-728e5abc="" x1="12" y1="5" x2="12" y2="19"></line>
          <line data-v-728e5abc="" x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
        {{ $trans('Add new') }}
      </button>
      <button @click="formFilter = !formFilter" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mr-1">
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        {{ $trans('Filter') }}
      </button>
    </h4>
    <div v-if="formFilter" class="card mb-3">
      <div class="card-body">
        <div class="d-flex">
          <div class="form-group mr-3">
            <label>{{ $trans('Keyword') }}</label>
            <input v-model="filterQuery.keyword" :placeholder="'keyword here!'" type="text" class="form-control">
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Service type') }}</label>
            <Select2 class="w-200" v-model="filterQuery.type" :options="seo_services"/>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Project') }}</label>
            <Select2 class="w-200" v-model="filterQuery.project_id" :options="projects"/>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Partner') }}</label>
            <Select2 class="w-200" v-model="filterQuery.partner_id" :options="partners"/>
          </div>
          <div class="form-group mr-3">
            <label>{{ $trans('Status') }}</label>
            <select class="form-control" v-model="filterQuery.status">
              <option value="all">{{ $trans('All') }}</option>
              <option v-for="(s,k) in order_status" :key="k" :value="k">{{ $trans(s) }}</option>
            </select>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group mr-3">
            <label class="d-block">{{ $trans('From date') }}</label>
            <vuejs-datepicker input-class="form-control" class="w-200" v-model="filterQuery.from"></vuejs-datepicker>
          </div>
          <div class="form-group  mr-3">
            <label class="d-block">{{ $trans('To date') }}</label>
            <vuejs-datepicker input-class="form-control" class="w-200" v-model="filterQuery.to"></vuejs-datepicker>
          </div>
        </div>
        <div class="filter-actions d-flex">
          <button @click="reset_filter" class="btn btn-outline-danger btn-sm mr-3">{{ $trans('Reset') }}</button>
          <button @click="index" class="btn btn-outline-primary btn-sm mr-3">
            <div v-if="process_loading" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Apply') }}
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="requests && requests.data" class="table-responsive">
          <div class="list-info mb-3">
            <small>{{ $trans('Results') }}: <strong>{{ requests.data.length }}</strong> {{ $trans('of') }}
              <strong>{{ requests.total }}</strong></small>
          </div>
          <table class="table table-striped mb-3">
            <thead>
            <tr>
              <th>{{ $trans('Ref Number') }}</th>
              <th>{{ $trans('Date') }}</th>
              <th>{{ $trans('Petitioner') }}</th>
              <th>{{ $trans('Type') }}</th>
              <th>{{ $trans('Project') }}</th>
              <th>{{ $trans('Partner') }}</th>
              <th class="text-right">{{ $trans('VND') }}</th>
              <th class="text-right">{{ $trans('USD') }}</th>
              <th>{{ $trans('Discount') }}</th>
              <th class="text-right">{{ $trans('Total VND') }}</th>
              <th class="text-right">{{ $trans('Total USD') }}</th>
              <th>{{ $trans('Note') }}</th>
              <th>{{ $trans('Status') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(request,index) in requests.data" v-bind:key="index">
              <td class="link" @click="detail(request)">{{ request.ref_number }}</td>
              <td>{{ request.date }}</td>
              <td>
                <slot v-if="request.user">{{ request.user.username }}</slot>
              </td>
              <td>{{ $trans(request.type) }}</td>
              <td>
                <slot v-if="request.project">{{ request.project.name }}</slot>
              </td>
              <td>
                <slot v-if="request.partner">{{ request.partner.name }}</slot>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    v-model="request.amount"
                    v-bind:read-only="true"
                    :minus="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    v-model="request.usd"
                    v-bind:read-only="true"
                    :minus="true"
                    v-bind:precision="2">
                </vue-numeric>
              </td>
              <td>
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency="%"
                    v-model="request.discount"
                    v-bind:read-only="true"
                    v-bind:precision="2">
                </vue-numeric>
              </td>
              <td class="text-success text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    v-model="request.total"
                    :minus="true"
                    v-bind:read-only="true"
                    v-bind:precision="0">
                </vue-numeric>
              </td>
              <td class="text-success text-right">
                <vue-numeric
                    currency-symbol-position="suffix"
                    currency=""
                    v-model="request.total_usd"
                    :minus="true"
                    v-bind:read-only="true"
                    v-bind:precision="2">
                </vue-numeric>
              </td>
              <td>{{ request.note }}</td>
              <td>
                <request-status :status="request.status"></request-status>
              </td>

              <td class="text-right td-control">
                <slot v-if="(request.status == 0 || request.status == -99 )&& request.user_id == user._id">
                  <a @click="edit(request)" class="mr-1" href="javascript:void(0)">
                    <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                         stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                  </a>
                </slot>
                <slot v-if="request.status == -99 && request.user_id == user._id">
                  <a @click="destroy(request)" class="text-danger" href="javascript:void(0)">
                    <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
                         stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                  </a>
                </slot>
              </td>
            </tr>
            </tbody>
          </table>
          <paginate v-if="requests && requests.data && requests.last_page"
                    :page-count="requests.last_page"
                    :click-handler="paginate"
                    :prev-text="$trans('Previous')"
                    :next-text="$trans('Next')"
                    :page-class="'page-item'"
                    :prev-class="'page-item'"
                    :next-class="'page-item'"
                    :page-link-class="'page-link'"
                    :prev-link-class="'page-link'"
                    :next-link-class="'page-link'"
                    :container-class="'pagination'">
          </paginate>
        </div>
      </div>
    </div>
    <modal v-if="addForm" v-bind:width="'800px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Add request') }}
          <button @click="addForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{ $trans('Fields Marked with (*) are required') }}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>{{ $trans('Service type') }} (*)</label>
              <Select2 class="" v-model="addFields.type" :options="seo_services"/>
              <slot v-if="errors && errors['type']">
                <div class="text-danger" v-for="error in errors['type']"
                     v-bind:key="error">
                  <small>{{ error }}</small>
                </div>
              </slot>
            </div>
            <div class="form-group">
              <label>{{ $trans('Project') }} (*)</label>
              <Select2 class="" v-model="addFields.project_id" :options="projects"/>
              <slot v-if="errors && errors['project_id']">
                <div class="text-danger" v-for="error in errors['project_id']"
                     v-bind:key="error">
                  <small>{{ error }}</small>
                </div>
              </slot>
            </div>
            <div class="form-group">
              <label>{{ $trans('Partner') }} (*)</label>
              <Select2 class="" v-model="addFields.partner_id" :options="partners"/>
              <slot v-if="errors && errors['partner_id']">
                <div class="text-danger" v-for="error in errors['partner_id']"
                     v-bind:key="error">
                  <small>{{ error }}</small>
                </div>
              </slot>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <label>{{ $trans('Detail') }} (*)</label>
              <table class="table-striped table">
                <thead>
                <tr>
                  <th>{{ $detail_title_text(addFields.type) }}</th>
                  <th>{{ $detail_text_text(addFields.type) }}</th>
                  <th>{{ $trans('VND') }}</th>
                  <th>{{ $trans('USD') }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,k) in detail_table" :key="k">
                  <td>
                    <input v-model="detail_table[k]['title']" class="form-control" type="text">
                  </td>
                  <td>
                    <input v-model="detail_table[k]['text']" class="form-control" type="text">
                  </td>
                  <td>
                    <vue-numeric class="form-control"
                                 currency-symbol-position="suffix"
                                 currency=""
                                 v-model="detail_table[k]['price']"
                                 v-bind:read-only="false"
                                 :minus="true"
                                 v-bind:precision="0">
                    </vue-numeric>
                  </td>
                  <td>
                    <vue-numeric class="form-control"
                                 currency-symbol-position="suffix"
                                 currency=""
                                 v-model="detail_table[k]['usd']"
                                 v-bind:read-only="false"
                                 :minus="true"
                                 v-bind:precision="2">
                    </vue-numeric>
                  </td>
                  <td class="text-right">
                    <a @click="removeDetail(k)" href="javascript:void(0)">
                      {{ $trans('Remove') }}
                    </a>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td colspan="4"></td>
                  <td class="text-right">
                    <a @click="addDetail()" href="javascript:void(0)">
                      {{ $trans('Add') }}
                    </a>
                  </td>
                </tr>
                </tfoot>
              </table>
              <slot v-if="errors && errors['detail']">
                <div class="text-danger" v-for="error in errors['detail']"
                     v-bind:key="error">
                  <small>{{ error }}</small>
                </div>
              </slot>
            </div>
            <div class="form-group">
              <label>{{ $trans('Note') }} (*)</label>
              <textarea class="form-control" v-model="addFields.note"></textarea>
            </div>
          </div>
        </div>
        <button @click="save_draft" class="btn btn-secondary mr-3">
          <div v-if="process_save_draft" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Save Draft') }}
        </button>
        <button @click="store" class="btn btn-primary">
          <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
          {{ $trans('Add & Request') }}
        </button>
      </slot>
    </modal>
    <modal v-if="editForm" v-bind:width="'800px'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Edit request') }}
          <button @click="editForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <small>{{ $trans('Fields Marked with (*) are required') }}</small>
        <hr>
        <div v-if="alert" class="alert" v-bind:class="'alert-'+alert.type">
          {{ alert.message }}
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label>{{ $trans('Service type') }} (*)</label>
              <Select2 class="" v-model="updateFields.type" :options="seo_services"/>
              <slot v-if="errors && errors['type']">
                <div class="text-danger" v-for="error in errors['type']"
                     v-bind:key="error">
                  <small>{{ error }}</small>
                </div>
              </slot>
            </div>
            <div class="form-group">
              <label>{{ $trans('Project') }} (*)</label>
              <Select2 class="" v-model="updateFields.project_id" :options="projects"/>
              <slot v-if="errors && errors['project_id']">
                <div class="text-danger" v-for="error in errors['project_id']"
                     v-bind:key="error">
                  <small>{{ error }}</small>
                </div>
              </slot>
            </div>
            <div class="form-group">
              <label>{{ $trans('Partner') }} (*)</label>
              <Select2 class="" v-model="updateFields.partner_id" :options="partners"/>
              <slot v-if="errors && errors['partner_id']">
                <div class="text-danger" v-for="error in errors['partner_id']"
                     v-bind:key="error">
                  <small>{{ error }}</small>
                </div>
              </slot>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <label>{{ $trans('Detail') }} (*)</label>
              <table class="table-striped table">
                <thead>
                <tr>
                  <th>{{ $detail_title_text(updateFields.type) }}</th>
                  <th>{{ $detail_text_text(updateFields.type) }}</th>
                  <th>{{ $trans('VND') }}</th>
                  <th>{{ $trans('USD') }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,k) in detail_table" :key="k">
                  <td>
                    <input v-model="detail_table[k]['title']" class="form-control" type="text">
                  </td>
                  <td>
                    <input v-model="detail_table[k]['text']" class="form-control" type="text">
                  </td>
                  <td>
                    <vue-numeric class="form-control"
                                 currency-symbol-position="suffix"
                                 currency=""
                                 v-model="detail_table[k]['price']"
                                 v-bind:read-only="false"
                                 :minus="true"
                                 v-bind:precision="0">
                    </vue-numeric>
                  </td>
                  <td>
                    <vue-numeric class="form-control"
                                 currency-symbol-position="suffix"
                                 currency=""
                                 v-model="detail_table[k]['usd']"
                                 v-bind:read-only="false"
                                 :minus="true"
                                 v-bind:precision="2">
                    </vue-numeric>
                  </td>
                  <td class="text-right">
                    <a @click="removeDetail(k)" href="javascript:void(0)">
                      {{ $trans('Remove') }}
                    </a>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td colspan="3"></td>
                  <td class="text-right">
                    <a @click="addDetail()" href="javascript:void(0)">
                      {{ $trans('Add') }}
                    </a>
                  </td>
                </tr>
                </tfoot>
              </table>
              <slot v-if="errors && errors['detail']">
                <div class="text-danger" v-for="error in errors['detail']"
                     v-bind:key="error">
                  <small>{{ error }}</small>
                </div>
              </slot>
            </div>
            <div class="form-group">
              <label>{{ $trans('Note') }} (*)</label>
              <textarea class="form-control" v-model="updateFields.note"></textarea>
            </div>
          </div>
        </div>
        <slot v-if="updateFields.status == -99">
          <button @click="update_draft" class="btn btn-secondary mr-3">
            <div v-if="process_save_draft" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Save Draft') }}
          </button>
          <button @click="update(1)" class="btn btn-primary">
            <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Update & Request') }}
          </button>
        </slot>
        <slot v-else>
          <button @click="update" class="btn btn-primary">
            <div v-if="process" class="spinner-border spinner-border-sm text-secondary"></div>
            {{ $trans('Update') }}
          </button>
        </slot>

      </slot>
    </modal>
    <modal v-if="detailForm" v-bind:width="'100%'" v-bind:height="'100vh'">
      <slot slot="body">
        <h5 class="mb-3">
          {{ $trans('Request Detail') }}
          <button @click="detailForm = false" class="close">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none"
                 stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </h5>
        <div class="row">
          <div class="col-sm-7">
            <table class="table table-striped">
              <tbody>
              <tr>
                <th class="w-200">{{ $trans('Ref Number') }}</th>
                <td>{{ request.ref_number }}</td>
              </tr>
              <tr>
                <th>{{ $trans('Petitioner') }}</th>
                <td>
                  <slot v-if="request.user">{{ request.user.username }}</slot>
                </td>
              </tr>
              <tr>
                <th>{{ $trans('Type') }}</th>
                <td>{{ $trans(request.type) }}</td>

              </tr>
              <tr>
                <th>{{ $trans('Project') }}</th>
                <td>
                  <slot v-if="request.project">{{ request.project.name }}</slot>
                </td>

              </tr>
              <tr>
                <th>{{ $trans('Partner') }}</th>
                <td>
                  <slot v-if="request.partner">{{ request.partner.name }}</slot>
                </td>

              </tr>
              <tr>
                <th>{{ $trans('Amount') }}</th>
                <td>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      v-model="request.amount"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>
              </tr>
              <tr>
                <th>{{ $trans('Discount') }}</th>
                <td>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency="%"
                      v-model="request.discount"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="2">
                  </vue-numeric>
                </td>
              </tr>
              <tr>
                <th>{{ $trans('Total amount') }}</th>
                <td>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      v-model="request.total"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>
              </tr>
              <tr>
                <th>{{ $trans('Status') }}</th>
                <td>
                  <request-status :status="request.status"></request-status>
                </td>
              </tr>
              <tr>
                <th>{{ $trans('Date') }}</th>
                <td>{{ request.created_at }}</td>
              </tr>
              <tr>
                <th>{{ $trans('Note') }}</th>
                <td class="pre-line">{{ request.note }}</td>
              </tr>
              <tr>
                <th>{{ $trans('Report URL') }}</th>
                <td colspan="2">{{ request.report_link }}</td>
              </tr>
              </tbody>
            </table>
            <h5>{{ $trans('Detail') }}</h5>
            <table class="table-striped table">
              <thead>
              <tr>
                <th>{{ $detail_title_text(request.type) }}</th>
                <th>{{ $detail_text_text(request.type) }}</th>
                <th>{{ $trans('VND') }}</th>
                <th>{{ $trans('USD') }}</th>
                <th>{{ $trans('Report Link') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,k) in request.detail" :key="k">
                <td v-html="$short_link(item.title)"></td>
                <td v-html="$short_link(item.text)"></td>
                <td>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      v-model="item.price"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="0">
                  </vue-numeric>
                </td>
                <td>
                  <vue-numeric
                      currency-symbol-position="suffix"
                      currency=""
                      v-model="item.usd"
                      v-bind:read-only="true"
                      :minus="true"
                      v-bind:precision="2">
                  </vue-numeric>
                </td>
                <td v-html="$short_link(item.report_link)"></td>
              </tr>
              </tbody>
            </table>
            <div class="form-actions">
              <slot v-if="request && request.status == 3 && request.user_id == user._id">
                <div class="form-group">
                  <label>{{ $trans('Feedback') }}(*)</label>
                  <textarea v-model="request.feedback" class="form-control"></textarea>
                  <slot v-if="errors && errors['feedback']">
                    <div class="text-danger" v-for="error in errors['feedback']"
                         v-bind:key="error">
                      <small>{{ error }}</small>
                    </div>
                  </slot>
                </div>
                <button @click="confirm(request,'done')" class="btn btn-primary mr-3">
                  <div v-if="process_change_status" class="spinner-border spinner-border-sm text-secondary"></div>
                  {{ $trans('Done & Request payment') }}
                </button>
                <button @click="adjustment(request,'adjustment')" class="btn btn-danger mr-3">
                  <div v-if="process_adjustment" class="spinner-border spinner-border-sm text-secondary"></div>
                  {{ $trans('Request an adjustment') }}
                </button>
              </slot>
            </div>
          </div>
          <div class="col-sm-5">
            <div v-if="logs" class="card shadow-0 border" style="background-color: #f0f2f5;">
              <div class="card-body p-4">
                <div v-for="(log,k) in logs" :key="k" class="card mb-2">
                  <div class="card-body p-2">
                    <div class="mb-1">
                      {{ log.title }}
                      <slot v-if="log.image">
                        <br>
                        <a target="_blank" :href="url+'/uploads/'+log.image">
                          <img :src="url+'/uploads/'+log.image" alt="">
                        </a>
                      </slot>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex flex-row align-items-center">
                        <img src="../assets/img/avatars/6.png" alt="" width="25" height="25"/>
                        <p class="small mb-0 ms-2">{{ log.username }}</p>
                      </div>
                      <div class="d-flex flex-row align-items-center">
                        <p class="small text-muted mb-0">{{ log.time }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slot>
    </modal>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Request",
  data: function () {
    return {
      process_change_status: false,
      process_save_draft: false,
      process_adjustment: false,
      addForm: false,
      detailForm: false,
      editForm: false,
      filterQuery: {
        page: 1,
        from: null,
        to: new Date(),
        status: 'all',
        partner_id: '',
        project_id: '',
        keyword: ''
      },
      alert: null,
      errors: {},
      process: false,
      addFields: {
        project_id: '',
        type: '',
        partner_id: '',
        note: ''
      },
      projects_res: null,
      requests: null,
      partners_res: null,
      detail_table: [
        {
          title: '',
          text: '',
          price: 0,
          usd: 0,
          report_link: ''
        }
      ],
      updateFields: {},
      process_loading: false,
      formFilter: false,
      request: null
    }
  },
  mounted() {
    var today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    if (month == 1) {
      month = 12;
      year = year - 1;
    }else {
      month = month -1
    }
    this.filterQuery.from = new Date(year, month, 1);
  },
  methods: {
    index: function () {
      this.process_loading = true
      axios({url: this.$root.$data.api_url + '/api/user/requests', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.requests = resp.data;
            this.process_loading = false
          })
          .catch(err => {
            console.log(err)
          })
    },
    reset_filter: function () {
        var today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        if (month == 1) {
          month = 12;
          year = year - 1;
        }else {
          month = month -1
        }
      this.filterQuery = {
        from: new Date(year,month, 1),
        to: new Date(),
        page: 1,
        status: 'all',
        partner_id: '',
        project_id: '',
        keyword: '',
      }
      this.index()
    },
    filter: function () {
      this.filterQuery.page = 1;
      this.index();
    },
    paginate: function (pageNum) {
      this.filterQuery.page = pageNum;
      this.index();
    },
    get_projects: function () {
      axios({url: this.$root.$data.api_url + '/api/user/projects', params: {}, method: 'GET'})
          .then(resp => {
            this.projects_res = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    get_partners: function () {
      axios({url: this.$root.$data.api_url + '/api/user/partners', params: {}, method: 'GET'})
          .then(resp => {
            this.partners_res = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    addDetail: function () {
      let item = {
        title: '',
        text: '',
        price: 0,
        usd: 0,
        report_link: ''
      }

      let items = this.detail_table
      items.push(item)
      this.detail_table = items
    },
    removeDetail: function (index) {
      let items = this.detail_table
      items.splice(index, 1);

      this.detail_table = items
    },
    store: function () {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.addFields
        data.detail = this.detail_table
        data.draft = 0
        axios({url: this.$root.$data.api_url + '/api/user/requests', data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.addForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    save_draft: function () {
      if (this.process_save_draft == false) {
        this.process_save_draft = true;
        this.errors = {}
        this.alert = null
        let data = this.addFields
        data.detail = this.detail_table
        data.draft = 1
        axios({url: this.$root.$data.api_url + '/api/user/requests', data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.addForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process_save_draft = false;
            })
            .catch(err => {
              console.log(err)
              this.process_save_draft = false;
            })
      }
    },
    edit: function (request) {
      axios({url: this.$root.$data.api_url + '/api/user/requests/' + request._id, method: 'GET'})
          .then(resp => {
            this.updateFields = resp.data.data;
            this.detail_table = resp.data.data.detail
            this.editForm = true;
          })
          .catch(err => {
            console.log(err)
          })
    },
    update: function (submit) {
      if (this.process == false) {
        this.process = true;
        this.errors = {}
        this.alert = null
        let data = this.updateFields
        data.detail = this.detail_table
        data.submit = submit
        axios({url: this.$root.$data.api_url + '/api/user/requests/' + data._id, data: data, method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.editForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process = false;
            })
            .catch(err => {
              console.log(err)
              this.process = false;
            })
      }
    },
    update_draft:function (){
      if (this.process_save_draft == false) {
        this.process_save_draft = true;
        this.errors = {}
        this.alert = null
        let data = this.updateFields
        data.detail = this.detail_table
        data.draft = 1
        axios({url: this.$root.$data.api_url + '/api/user/requests/' + data._id, data: data, method: 'PUT'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.editForm = false;
                this.index();
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process_save_draft = false;
            })
            .catch(err => {
              console.log(err)
              this.process_save_draft = false;
            })
      }
    },
    destroy:function (request){
      var actionConfirm = confirm("Are you sure?");
      if (actionConfirm === false) {
        return false;
      }
      axios({url: this.$root.$data.api_url + '/api/user/requests/' + request._id, method: 'DELETE'})
          .then(() => {
            this.index();
          })
          .catch(err => {
            console.log(err)
          })
    },
    detail: function (request) {
      axios({url: this.$root.$data.api_url + '/api/user/requests/' + request._id, method: 'GET'})
          .then(resp => {
            this.request = resp.data.data;
            this.detailForm = true;
          })
          .catch(err => {
            console.log(err)
          })
    },
    confirm: function (request, action) {
      if (this.process_change_status == false) {
        this.process_change_status = true;
        this.errors = {}
        this.alert = null
        let data = {
          action: action,
          id: request._id,
          feedback: request.feedback
        }
        axios({url: this.$root.$data.api_url + '/api/user/requests/confirm/' + request._id, data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.addForm = false;
                this.index();
                this.detail(request)
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process_change_status = false;
            })
            .catch(err => {
              console.log(err)
              this.process_change_status = false;
            })
      }
    },
    adjustment: function (request, action) {
      if (this.process_adjustment == false) {
        this.process_adjustment = true;
        this.errors = {}
        this.alert = null
        let data = {
          action: action,
          id: request._id,
          feedback: request.feedback
        }
        axios({url: this.$root.$data.api_url + '/api/user/requests/confirm/' + request._id, data: data, method: 'POST'})
            .then(resp => {
              let error_code = resp.data.error_code;
              if (error_code == 0) {
                this.addForm = false;
                this.index();
                this.detail(request)
              } else {
                this.alert = {
                  type: 'warning',
                  message: resp.data.message
                }
                this.errors = resp.data.errors || {};
              }
              this.process_adjustment = false;
            })
            .catch(err => {
              console.log(err)
              this.process_adjustment = false;
            })
      }
    },
    detail_title_text: function (type) {
      switch (type) {
        case '':
          return 'Package'
        default:
          return 'Domain/Package/...'
      }
    }
  },
  created() {
    this.index()
    this.get_projects()
    this.get_partners()
  },
  computed: {
    seo_services: function () {
      return this.$root.$data.global_settings.seo_services
    },
    order_status: function () {
      return this.$root.$data.global_settings.order_status
    },
    projects: function () {
      if (!this.projects_res) {
        return [];
      }
      let projects = [];
      let projects_res = this.projects_res;
      projects_res.forEach((el) => {
        let item = {
          id: el._id,
          text: el.name
        }
        projects.push(item)
      })

      return projects;
    },
    partners: function () {
      if (!this.partners_res) {
        return [];
      }
      let partners = [];
      let partners_res = this.partners_res;
      partners_res.forEach((el) => {
        let item = {
          id: el._id,
          text: el.name
        }
        partners.push(item)
      })

      return partners;
    },
    user: function () {
      return this.$root.user
    },
    url: function () {
      return this.$root.$data.api_url
    },
    logs: function () {
      if (!this.request) {
        return null
      }
      if (!this.request.logs) {
        return null
      }
      let logs = this.request.logs
      return logs.reverse()
    }
  }
}
</script>

<style scoped>

</style>